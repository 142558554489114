<template>
  <!-- 员工中心 -->
  <AppBar>
    <div class="top_box">
      <el-form :inline="true" label-width="90px">
        <el-form-item label="代码：" prop="code">
          <el-input
            placeholder="请输入代码"
            clearable
            size="small"
            v-model="queryParams.code"
          />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input
            placeholder="请输入名称"
            clearable
            size="small"
            v-model="queryParams.name"
          />
        </el-form-item>
        <el-form-item>
          <el-button
            type="primary"
            icon="el-icon-search"
            size="mini"
            @click="handleQuery"
            >搜索</el-button
          >
          <el-button icon="el-icon-refresh" size="mini" @click="resetQuery"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="body_box">
      <el-row :gutter="10" class="mb8">
        <el-col :span="1.5">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            @click="handleAdd"
            >新增</el-button
          >
        </el-col>
      </el-row>
      <el-table :data="memberList" style="margin-top: 20px;">
        <!-- <el-table-column type="selection" width="55" align="center" /> -->
        <el-table-column label="代码" align="center" prop="login" />
        <el-table-column label="名称" width="110" align="center" prop="name" />
        <el-table-column label="职位" align="center" prop=" position">
          <template slot-scope="scope">{{
            positionsParams[scope.row.position]
          }}</template>
        </el-table-column>
        <el-table-column label="手机" align="center" width="110" prop="mobile" />
        <el-table-column
          label="所属组织"
          align="center"
          prop="belongOrg.namePath"
        />
        <el-table-column label="是否启用" align="center" prop="enabled">
          <template slot-scope="scope">{{
            scope.row.enabled == "1" ? "启用" : "禁用"
          }}</template>
        </el-table-column>
        <el-table-column label="第三方登录" width="100" align="center" prop="thirdLogin">
          <template slot-scope="scope">{{
            scope.row.thirdLogin == "1" ? "启用" : "禁用"
          }}</template>
        </el-table-column>

        <el-table-column label="最后操作信息" align="center" width="280">
          <template slot-scope="scope">
            [{{ scope.row.lastModifier }}]{{ scope.row.lastModifierId }} {{ scope.row.lastModified }}
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center" prop="status" width="180">
          <template slot-scope="scope">
            <!-- <el-button type="danger" plain icon="el-icon-delete" size="mini"
              >删除</el-button
            > -->
            <el-button
              type="text"
              icon="el-icon-delete"
              plain
              size="mini"
              @click="enableUserEvent(scope.row)"
              >启用</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              plain
              size="mini"
              @click="disableUserEvent(scope.row)"
              >禁用</el-button
            >
            <el-button
              plain
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(scope.row)"
              >修改</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog
      :close-on-click-modal="false"
      :visible.sync="open"
      width="700px"
      append-to-body
    >
      <el-form ref="form" :model="form" label-width="120px">
        <el-form-item label="登录名：" prop="login" required>
          <el-input v-model="form.login" placeholder="请输入登录名" />
        </el-form-item>
        <el-form-item label="名称" prop="name">
          <el-input v-model="form.name" placeholder="请输入名称" />
        </el-form-item>
        <el-form-item label="密码" prop="password" v-if="!editFlag">
          <el-input v-model="form.password" placeholder="请输入密码" />
        </el-form-item>
        <el-form-item label="确认密码" prop="confirmPassword" v-if="!editFlag">
          <el-input
            v-model="form.confirmPassword"
            placeholder="请输入确认密码"
          />
        </el-form-item>
        <el-form-item label="第三方登录" prop="thirdLogin" v-if="!editFlag">
          <el-select
            v-model="form.thirdLogin"
            class="width240"
            style="width: 100%"
            placeholder="请选择是否第三方登录"
          >
            <el-option
              v-for="item in otherLogins"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-checkbox-group v-model="form.roles">
            <el-checkbox
              v-for="item in roleList"
              :label="item.uuid"
              :key="item.uuid"
              >{{ item.name }}</el-checkbox
            >
          </el-checkbox-group>
          <!-- <el-input v-model="form.roles" placeholder="请输入角色" /> -->
        </el-form-item>
        <el-form-item label="职位" prop="position">
          <el-select
            v-model="form.position"
            class="width240"
            style="width: 100%"
            placeholder="请选择职位"
          >
            <el-option
              v-for="item in positionsParamsList"
              :key="item.value"
              :label="item.name"
              :value="item.value"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="form.position" placeholder="请输入职位" /> -->
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="form.address" placeholder="请输入地址" />
        </el-form-item>
        <el-form-item label="手机" prop="mobile">
          <el-input v-model="form.mobile" placeholder="请输入手机" />
        </el-form-item>
        <el-form-item label="Email" prop="email">
          <el-input v-model="form.email" placeholder="请输入Email" />
        </el-form-item>
        <el-form-item label="所属组织" prop="belongOrg">
          <el-select
            v-model="form.belongOrg"
            class="width240"
            style="width: 100%"
            placeholder="请选择"
          >
            <el-option
              v-for="group in orgTreeList"
              :key="group.id"
              :label="group.text"
              :value="group.id"
            >
            </el-option>
          </el-select>
          <!-- <el-input v-model="form.belongOrg" placeholder="请输入所属组织" /> -->
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submitForm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </el-dialog>
    <pagination
      :total="total"
      :page.sync="queryParams.pageNumber"
      :limit.sync="queryParams.pageSize"
      @pagination="getMore"
      style="display: flex;align-items: center;justify-content: center;"
    />
  </AppBar>
</template>

<style lang="less" scoped>
.content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  background-color: white;
  > i {
    width: 360px;
  }
  .box {
    position: relative;
    width: 360px;
    height: 170px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    margin-bottom: 15px;

    .main {
      position: relative;
      width: 86%;
      height: 80%;
      display: flex;
      flex-direction: row;
      align-items: center;

      .one {
        position: relative;
        height: 100%;
        width: 36%;

        .el-image {
          height: 100%;
          width: 100%;
          border-radius: 4px;
        }
      }
      .two {
        margin-left: 20px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-around;

        > p {
          color: #afafaf;
          text-overflow: ellipsis;
          overflow: hidden;
          white-space: nowrap;
          max-width: 120px;

          > span {
            color: #696c70;
          }
        }
      }
    }
  }
}
</style>

<script>
import AppBar from "@/components/AppBar";
import pagination from "@/components/Pagination";
import Api from "@/api/index";
export default {
  data() {
    return {
      otherLogins: [
        {
          name: "是",
          value: true,
        },
        {
          name: "否",
          value: false,
        },
      ], // 第三方登录
      positionsParamsList: [
        {
          value: 'none',
          name: '无'
        },
        {
          value: 'manager',
          name: '管理层人员'
        },
        {
          value: 'storer',
          name: '店长'
        },
        {
          value: 'inspector',
          name: '巡检人员'
        }
      ], // 职位
      orgTreeList: [], // 组织数据
      roleList: [], // 角色数据
      positionsParams: {
        "none": "无",
        "manager": "管理层人员",
        "storer": "店长",
        "inspector": "巡检人员"
      },
      currentPage: 1,
      total: 10,
      form: {
        roles: []
      },
      queryParams: {
        pageNumber: 1,
        pageSize: 10,
        code: "",
        name: ""
      },
      memberList: [],
      open: false,
      editFlag: false,
      loginMsg: JSON.parse(sessionStorage.getItem("loginMsg"))
    };
  },
  methods: {
    // 所属组织
    getOrgTreeList() {
      Api.getOrgTree().then(res => {
        var dataList = res.data.data.children;
        var data = res.data.data;
        this.orgTreeList.push(data);
        dataList.forEach(val => {
          this.orgTreeList.push(val);
        });
        console.log(this.orgTreeList);
      });
    },
    // 职位
    getAllPositionList() {
      console.log("进入");
      Api.getAllPosition().then(res => {
        if (res.data && res.data.success) {
          this.positionsParams = res.data.data;
        }
      });
    },
    // 角色
    getAllList() {
      Api.getRoleAll().then(res => {
        if (res.data && res.data.success) {
          this.roleList = res.data.data;
        }
      });
    },
    // 分页
    getMore(pagination) {
      if (pagination.page === this.queryParams.pageNumber) {
        this.queryParams.pageSize = pagination.limit;
      } else {
        this.queryParams.pageNumber = pagination.page;
      }
      this.queryList();
    },
    // 修改员工弹窗显示
    edit(row) {
      this.editFlag = true;
      Api.getUser(row.uuid).then(res => {
        if (res.data && res.data.success) {
          res.data.data.position = this.positionsParams[res.data.data.position];
          let roles = [];
          res.data.data.roles.forEach(item => {
            roles.push(item.uuid);
          });
          this.form = res.data.data;
          this.form.roles = roles;
          this.form.belongOrg = res.data.data.belongOrg && res.data.data.belongOrg.uuid;
          this.open = true;
        } else {
          this.$message.error(res.data.message)
        }
      });
    },
    // 查询
    handleQuery() {
      this.queryList({
        ...this.queryParams
      }).then(res => {
        console.log(res);
      });
    },
    // 重置查询条件
    resetQuery() {
      this.queryParams = {
        pageNumber: 1,
        pageSize: 10,
        code: "",
        name: ""
      };
      this.queryList();
    },
    // 新增员工弹窗显示
    handleAdd() {
      this.open = true;
      this.title = "";
      this.editFlag = false;
      this.form = {
        roles: []
      };
    },
    // 取消新建或修改
    cancel() {
      this.open = false;
      this.form = {
        roles: []
      };
    },
    // 获取列表数据
    queryList() {
      Api.queryUser({
        ...this.queryParams
      })
        .then(res => {
          if(res.data && res.data.success) {
            this.memberList = res.data.data.records;
            this.total = res.data.data.paging.recordCount;
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          this.$message.error(err.message)
        });
    },
    // 禁用员工
    disableUserEvent(data) {
      console.log(data.uuid);
      Api.disableUser(data.uuid)
        .then(res => {
          console.log(res);
          this.$message.success("禁用用成功");
          this.queryList();
        })
        .catch(err => {
          this.$message.error(err.message);
          console.log(err);
        });
    },
    // 启用员工
    enableUserEvent(data) {
      console.log(data.uuid);
      Api.enableUser(data.uuid)
        .then(res => {
          console.log(res);
          this.$message.success("启用成功");
          this.queryList();
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    // 新建或修改员工数据保存
    submitForm(data) {
      console.log(data);
      let params = Object.assign({}, this.form);
      // "createInfo":{"time":1643212800000,"operator":{"id":"admin","operName":"超级管理员"},"enterprise":null},"
      // params.createInfo = {
      //   time: new Date().getTime(),
      //   operator: {
      //     id: params.creatorId || this.loginMsg.user_code,
      //     operName: params.creator || this.loginMsg.user_name
      //   }
      // }
      // 处理新建与修改不同的数据
      params.created = params.created || this.getSelfTime(new Date());
      params.creatorId = params.creatorId || this.loginMsg.user_code;
      params.creator = params.creator || this.loginMsg.user_name;
      params.enterprise = params.enterprise || this.loginMsg.enterprise_uuid;
      params.belongOrg = {
        uuid: this.form.belongOrg
      }
      params.orguuid = this.form.belongOrg;
      params.enabled = params.enabled || 1;
      params.businessType = 999;
      params.thirdLogin = params.thirdLogin || false;
      // params.position = params.position || 'storer';
      // 匹配职位数据
      for (let k in this.positionsParams) {
        if (this.positionsParams[k] === params.position) {
          params.position = k;
        }
      }
      params.roles = [];
      this.form.roles.forEach(item => {
        params.roles.push({
          uuid: item
        })
      })
      // 调用接口
      Api.addUser(params)
        .then(res => {
          if(res.data && res.data.success) {
            if (this.editFlag) {
              this.$message.success("修改成功");
            } else {
              this.$message.success("新建成功");
            }
            this.queryList();
            this.open = false;
            console.log(res);
          } else {
            this.$message.error(res.data.message)
          }
        })
        .catch(err => {
          console.log(err);
          this.$message.error(err.message);
        });
    },
    // 处理时间
    getSelfTime(date){
      let y = date.getFullYear();
      let M = date.getMonth() + 1;
      let d = date.getDate();
      let h = date.getHours();
      let m = date.getMinutes();
      let s = date.getSeconds();
      return y + '-' + (M >= 10? M : '0' + M) + '-' + (d >= 10? d : '0' + d) + ' ' + (h >= 10? h : '0' + h) + ':' + (m >= 10? m : '0' + m) + ':' + (s >= 10? s : '0' + s);
    }
  },
  mounted() {
    this.getAllList();
    this.getOrgTreeList();
    // 初始化获取列表数据
    Api.queryUser({
      ...this.queryParams
    })
      .then(res => {
        console.log(res);
        if(res.data && res.data.success) {
          this.memberList = res.data.data.records;
          this.total = res.data.data.paging.recordCount;
        }
      })
      .catch(err => {
        console.log(err);
      });
  },
  components: {
    AppBar,
    pagination
  }
};
</script>
